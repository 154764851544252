<template>
  <div class="header__logo-container">
    <a-visibility show :from="$breakpoint.desktopMd">
      <a-link :class="logoClassList" :to="ROUTE.HOMEPAGE">
        <img
          :src="$helper.getLinkToFmBucketFile('header-logo-2.svg')"
          alt="Financial and Business News"
          title="Financial and Business News"
          class="header__logo"
        />
      </a-link>
    </a-visibility>

    <a-visibility show :on="[$breakpoint.desktopSm]">
      <a-icon
        :icon="ICON.FM_MOBILE"
        :width="32"
        :height="46"
        :to="ROUTE.HOMEPAGE"
        aria-label="Go to FM Homepage"
        fill="#fff"
        no-hover
        class="header__logo logo-sm"
      />
    </a-visibility>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { ROUTE } from 'enums/routes'
import AIcon, { ICON } from 'shared/AIcon'

export default {
  name: 'AHeaderLogo',
  components: { AIcon },
  data() {
    return { ROUTE, ICON }
  },
  computed: {
    ...mapGetters({
      isHeaderCollapsed: 'isHeaderCollapsed'
    }),
    logoClassList() {
      return {
        header__logo: true,
        header__logo_collapsed: this.isHeaderCollapsed
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header__logo-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .header__logo {
    display: block;
    width: 100%;
    height: 100%;
    transition: height 0.5s, width 0.5s;
    font-size: 10px;
    line-height: 14px;

    & .logo-sm {
      width: 32px;
      height: 46px;

      @include desktop-sm {
        height: auto;
      }
    }
  }

  .header__logo_collapsed {
    width: 79px;
    height: 33px;
  }
}
</style>
